<template>
  <div v-loading="loading">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px" style="margin-bottom:80px">
      <el-card header="基本信息">
        <el-form-item label="选择商品：" prop="ProductId">
          <div class="shopBox flex flex-align-center margin-bottom-10" v-if="form.ProductId">
            <img :src="form.ImgUrlComplete" alt="">
            <div class="line-font-1_5 flex-1" style="width:0">
              <div class="font-14 ellipsis">
                <span class="color-f56c6c" v-if="form.ProductType==1">[组合]</span>
                <span class="color-606266">{{form.ProductName}}</span>
              </div>
              <div class="color-999999 font-12 margin-top-5 ellipsis">
                {{form.ProductNo}}
              </div>
            </div>
          </div>
          <el-button type="info" class="btn-choose" v-if="!form.Id" @click="selectProShow=true">{{form.ProductId?'重新选择':'选择商城商品'}}</el-button>
        </el-form-item>
        <el-form-item label="商品标题：" prop="Title" v-if="form.ProductId">
          <el-input v-model="form.Title" placeholder="请输入5~60个字的商品标题" maxlength="60"
           @blur="cutOut($event,'Title',60)" style="width:500px"></el-input>
        </el-form-item>
        <el-form-item label="商品描述：" v-if="form.ProductId">
          <el-input v-model="form.Description" placeholder="最多18个字" maxlength="18"
           @blur="cutOut($event,'Description',18)" style="width:500px"></el-input>
        </el-form-item>
        <el-form-item label="商品类目：" prop="category">
          <el-select v-model="form.category" placeholder="请选择商品类目" filterable style="width:240px" @change="getCategoryDetail"
            @visible-change="searchCategory">
            <div slot="empty" class="font-12 line-font-1_5" style="color:#C0C4CCFF;padding:11px 36px;max-width:240px" v-loading="Categoryloading">
              暂无数据，请前往视频号小店添加类目，或点击刷新重新获取
            </div>
            <el-option v-for="v in categoryOptions" :key="v.Id" :value="v.Id" :label="v.label"></el-option>
          </el-select>
          <el-button type="text" class="margin-left-10" @click="getCategory">刷新</el-button>
        </el-form-item>
        <el-form-item label="商品品牌：">
          <el-select v-model="form.BrandId" placeholder="请选择商品品牌" filterable style="width:240px" @change="getBrandName"
            @visible-change="searchBrand">
            <div slot="empty" class="font-12 line-font-1_5" style="color:#C0C4CCFF;padding:11px 36px;max-width:240px" v-loading="Brandloading">
              暂无数据，请前往视频号小店添加品牌，或点击刷新重新获取
            </div>
            <el-option v-for="v in brandList" :key="v.Id" :value="v.Id" :label="v.label"></el-option>
          </el-select>
          <el-button type="text" class="margin-left-10" @click="getBrand">刷新</el-button>
        </el-form-item>
      </el-card>
      <el-card class="margin-top-10">
        <div slot="header" class="font-16 color-303133">
          <span>规格售价</span>
          <span class="font-14 color-999999 margin-left-10">选中商城内的商品后，按规格设置在视频号小店中的售价</span>
        </div>
        <div style="width:800px;border: 1px solid #EBEEF5" v-if="form.ProductId">
          <el-table :data="form.SpecList" max-height="500">
            <el-table-column prop="Spec" label="规格">
              <template slot-scope="scope">
                <div class="ellipsis-two">{{scope.row.Spec}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="规格条形码">
              <template slot-scope="scope">
                <div class="" style="white-space: nowrap;">
                  {{scope.row.Barcode||'--'}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="SpecPrice" label="商城售价(元)"></el-table-column>
            <el-table-column prop="" label="小店售价(元)">
              <template slot-scope="scope">
                <el-form-item label-width="0px" :prop="'SpecList.'+scope.$index+'.Price'" :rules="rules.Price" style="margin-top:22px">
                  <el-input style="width:130px" v-model="scope.row.Price"
                    oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-bottom flex">
            <div class="flex flex-align-center" v-if="batchSetShow">
              <el-form-item label-width="0px" prop="batchShopPrice" :rules="rules.Price" class="margin-right-10" style="margin-top:22px">
                <el-input style="width:130px" v-model="form.batchShopPrice" placeholder="请输入售价" ref="setInput"
                  oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
              </el-form-item>
              <el-button type="text" @click="confirmSet">确定</el-button>
              <el-button type="text" @click="batchSetShow=false,form.batchShopPrice=''">取消</el-button>
            </div>
            <el-button type="text" v-else @click="ShowSet">批量设置小店售价</el-button>
          </div>
        </div>
        <div v-else class="font-14 color-999999" style="padding:20px;text-align:center">
          请先选择商品
        </div>
      </el-card>
      <el-card class="margin-top-10">
        <div slot="header" class="font-16 color-303133">
          <span>商品参数</span>
          <span class="font-14 color-999999 margin-left-10">选中商品类目，按视频号小店商品管理要求填写类目相关参数</span>
        </div>
        <div v-loading="ProductAttrloading">
          <div v-if="form.category">
            <div class="flex flex-align-center flex-justify-start flex-wrap" v-if="form.ProductAttr&&form.ProductAttr.length">
              <div v-for="(v,i) in form.ProductAttr" :key="i+''+form.category">
                <el-form-item :label="v.name" label-width="240px" :required="v.is_required" :prop="'ProductAttr.'+i+'.value'" :rules="rules.argumentValue">
                  <el-input v-if="v.type=='string'" style="width:200px" placeholder="请输入" v-model="v.value" clearable></el-input>
                  <el-select v-else style="width:200px" placeholder="请选择" v-model="v.value" clearable filterable>
                    <el-option v-for="(x,y) in v.Options" :key="y" :value="x.value" :label="x.label"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="flex flex-align-center flex-justify-center color-f56c6c font-14" v-else>
              无效的三级类目id，请重新选择类目
            </div>
          </div>
          <div v-else class="font-14 color-999999" style="padding:20px;text-align:center">
            请先选择类目
          </div>
        </div>
      </el-card>
      <el-card class="margin-top-10" header="其他设置">
        <el-form-item label="物流配送：" prop="FreightTemplateId">
          <el-select v-model="form.FreightTemplateId" placeholder="请选择运费模板" filterable style="width:240px" @change="getTpDetail">
            <div slot="empty" class="font-12 line-font-1_5" style="color:#C0C4CCFF;padding:11px 36px;max-width:240px" v-loading="FreightTemplateloading">
              暂无数据，请前往视频号小店添加，或点击刷新重新获取
            </div>
            <el-option v-for="v in logisticsOptions" :key="v.Id" :value="v.Id" :label="v.label"></el-option>
          </el-select>
          <el-button type="text" class="margin-left-10" @click="getLogistics">刷新</el-button>
          <div class="margin-top-10 board font-14 color-606266" style="width:760px" v-if="form.FreightTemplateId">
            <div v-if="form.tpDetail">
              <div class="flex flex-align-center">
                <div style="width:100px;text-align:left;flex-shrink:0">发货</div>
                <div>
                  {{form.tpDetail.address_info.city_name}} 
                  <span class="margin-left-5">{{form.tpDetail.shipping_method=='FREE'?'包邮':(form.tpDetail.shipping_method=='NO_FREE'?'不包邮':'条件包邮')}}</span>
                </div>
              </div>
              <div class="flex flex-align-center margin-top-20" v-if="form.tpDetail.shipping_method!='FREE'">
                <div style="width:100px;text-align:left;flex-shrink:0">默认运费</div>
                <div v-if="form.tpDetail.Defaule">
                  {{form.tpDetail.Defaule.address_infos.map(x=>x.province_name).join('、')}}{{form.tpDetail.Defaule.first_val_amount}}{{form.tpDetail.valuation_type=='PIECE'?'件':'kg'}}内{{form.tpDetail.Defaule.first_price}}元,每增加{{form.tpDetail.Defaule.second_val_amount}}{{form.tpDetail.valuation_type=='PIECE'?'件':'kg'}}加{{form.tpDetail.Defaule.second_price}}元
                </div>
              </div>
              <div class="flex flex-align-start margin-top-20" v-if="form.tpDetail.NotDefaule&&form.tpDetail.NotDefaule.length">
                <div style="width:100px;text-align:left;flex-shrink:0">指定运费</div>
                <div>
                  <span v-for="(v,i) in form.tpDetail.NotDefaule" :key="i">
                    {{v.address_infos.map(x=>x.province_name).join('、')}}{{v.first_val_amount}}{{form.tpDetail.valuation_type=='PIECE'?'件':'kg'}}内{{v.first_price}}元,每增加{{v.second_val_amount}}{{form.tpDetail.valuation_type=='PIECE'?'件':'kg'}}加{{v.second_price}}元。
                  </span>
                </div>
              </div>
            </div>
            <div v-else style="text-align:center">
              模板详情获取失败，<el-button type="text" @click="refreshLogistics">重新获取</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="商品重量：" prop="Weight">
          <el-input style="width:240px" placeholder="请输入重量" v-model="form.Weight"
            oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{3})[0-9]*/,'$1'))"></el-input>
          <span class="font-14 color-606266 margin-left-10">KG</span>
        </el-form-item>
        <el-form-item label="7天无理由：" prop="SevenDayReturn">
          <el-select v-model="form.SevenDayReturn" placeholder="请选择7天无理由" filterable style="width:240px">
            <el-option v-for="v in SevenDayReturnList" :key="v.value" :value="v.value" :label="v.label"></el-option>
          </el-select>
          <div class="font-14 color-999999">
            满足条件时，消费者可申请"7天无理由"进行退换货。
            <el-link href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/h2NqoTz2yHFAyJkj"
            :underline="false" target="_blank" style="color:#409eff;margin-top:-2px">规则详情</el-link>
          </div>
        </el-form-item>
        <el-form-item label="商品限购：">
          <el-checkbox v-model="form.Limited">开启</el-checkbox>
          <div v-if="form.Limited">
            <div class="flex flex-align-center font-14 color-606266 gap-10 margin-top-10">
              <span>每人</span>
              <el-select v-model="form.PeriodType" style="width:100px">
                <el-option v-for="v in PeriodTypeList" :key="v.value" :value="v.value" :label="v.label"></el-option>
              </el-select>
              <span>限购</span>
              <el-form-item label-width="0" prop="LimitedBuyNum" style="width:200px" key="LimitedBuyNum">
                <div class="flex flex-align-center font-14 color-606266 gap-10">
                  <el-input style="width:100px" v-model="form.LimitedBuyNum"
                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  <span>件</span>
                </div>
              </el-form-item>
            </div>
            <div class="font-14 color-999999">每日0:00刷新限购资格</div>
          </div>
        </el-form-item>
        <el-form-item label="商品资质：">
          <div class="flex flex-align-center flex-wrap gap-10">
            <div class="imgbox" v-for="(v,i) in form.Qualifications" :key="i">
              <img :src="imgUrl+v" alt="">
              <div class="maskbox">
                <div class="mask">
                  <i class="el-icon-zoom-in" @click="handlePictureCardPreview(v)"></i>
                  <i class="el-icon-delete" @click="handleRemove(i)"></i>
                </div>
              </div>
            </div>
            <el-upload :action="action" list-type="picture-card" accept="image/*" :before-upload="beforeUpload" class="aptitude"
              :on-success="onSuccess" :show-file-list="false" v-if="form.Qualifications.length<5" multiple>
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="font-14 color-999999">最多可上传5张图片；图片最大10M；格式支持bmp,png,jpeg,jpg</div>
        </el-form-item>
        <el-form-item label="售后说明：">
          <el-input v-model="form.AfterSaleDesc" type="textarea" placeholder="售后说明最多200个字" maxlength="200" :rows="6" style="width:500px"></el-input>
        </el-form-item>
      </el-card>
    </el-form>

    <el-card class="bottomCard" style="padding-left:7%">
      <el-button @click="$router.push({path:'/videoShop/videoGoodsList'})">取消</el-button>
      <el-button type="primary" @click="submit('ruleForm')" v-if="form.State!=2">提交审核</el-button>
    </el-card>
    <el-dialog title="选择商品" :visible.sync="selectProShow" v-if="selectProShow" width="1100px" class="dialog data-dialog">
      <select-produce :proCheckedId='form.ProductId||0' :couponData="{id:form.Id}" @getSelection='getSelectPros' :Num='3'
        :showOnlyChoose="false" :selectData="{
          Id:form.ProductId,
          Name:form.ProductName,
          ProductSpecList:form.SpecList,
          ProductType:form.ProductType,
          ProductNo:form.ProductNo,
          ImgUrlComplete:form.ImgUrlComplete,
          SellPoint:form.SellPoint
        }"></select-produce>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" width="550px">
      <div style="width:500px;max-height:500px;overflow-y:auto">
        <img width="100%" :src="dialogImageUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import selectProduce from '@/views/market/association/turntableActivities'
import config from "@/config"
import {
  channelShopchannelShopCategoryList,
  channelShopchannelShopBrandList,
  productproductspecinfo,
  channelShopchannelShopCategoryDetail,
  channelShopchannelShopFreightTemplateList,
  channelShopchannelShopProductSubmit,
  channelShopchannelShopProductDetail
} from "@/api/goods"
export default {
  components:{
    selectProduce
  },
  data () {
    var checkPrice = (rule, value, callback) => {
      if(!value||value<0.01){
        callback(new Error('价格最小0.01元'))
      }
      if(value>10000000||!this.two.test(value)){
        callback(new Error('请输入正确售价'))
      }
      callback()
    }
    var checkargumentValue = (rule, value, callback) => {
      let index = rule.fullField.split('.')[1]
      let row = this.form.ProductAttr[index]
      if(!row.is_required){
        callback()
      }
      if(String(value)==''||!String(value)){
        callback(new Error(`${row.type=='string'?'请输入':'请选择'}${row.name}`))
      }
      callback()
    }
    var checkWeight = (rule, value, callback) => {
      if(String(value)==''){
        callback()
      }
      if(value<0.001||value>999.99||!this.three.test(value)){
        callback(new Error('重量请输入0.001~999.99千克之间，3位小数'))
      }
      callback()
    }
    var checkLimitedBuyNum = (rule, value, callback) => {
      if(!this.form.Limited){
        callback()
      }
      if(value<1||value>100){
        callback(new Error('件数请设置在1~100件之间，整数'))
      }
      callback()
    }
    var checkLimited = (rule, value, callback) => {
      callback()
    }
    return {
      loading:false,
      imgUrl:config.IMG_BASE,
      action:config.UPLOAD_IMG,
      form:{
        Id:0,
        ProductId:'',
        ProductName:'',
        OutProductId:'',
        ChannelShopConfigId:'',
        Title:'',
        Description:'',
        ProductType:0,
        ProductNo:'',
        ImgUrlComplete:'',
        SellPoint:'',
        SpecList:[],

        FirstCategoryId:'',
        FirstCategoryname:'',
        SecondCategoryId:'',
        SecondCategoryname:'',
        ThirdCategoryId:'',
        ThirdCategoryname:'',
        category:'',
        BrandId:'',
        BrandName:'',
        ProductAttr:[],
        FreightTemplateId:'',
        FreightTemplateName:'',
        tpDetail:{},
        Weight:'',
        SevenDayReturn:0,
        PeriodType:1,
        Limited:false,
        LimitedBuyNum:'',
        Qualifications:[],
        AfterSaleDesc:'',

        batchShopPrice:'',
      },
      rules:{
        ProductId:[
          { required: true, message: '请选择商品', trigger: 'blur' },
        ],
        Title:[
          { required: true, message: '请输入5~60个字的商品标题', trigger: 'blur', min: 5, max: 60, },
        ],
        category:[
          { required: true, message: '请选择商品类目', trigger: 'blur' },
        ],
        Price:[{
          required:true,
          validator: checkPrice,
          trigger: 'blur'
        }],
        argumentValue:[{
          validator: checkargumentValue,
          trigger: ['change','blur']
        }],
        FreightTemplateId:[
          { required: true, message: '请选择运费模板', trigger: 'blur' },
        ],
        Weight:[{
          validator: checkWeight,
          trigger: 'blur'
        }],
        SevenDayReturn:[
          { required: true, message: '请选择7天无理由', trigger: 'blur' },
        ],
        Limited:[{
          required: true,
          validator: checkLimited,
          trigger: 'blur'
        }],
        LimitedBuyNum:[{
          validator: checkLimitedBuyNum,
          trigger: 'blur'
        }]
      },
      selectProShow:false,
      categoryOptions:[],
      brandList:[],
      logisticsOptions:[],
      SevenDayReturnList:[
        {value:0,label:'不支持7天无理由'},
        {value:1,label:'支持7天无理由'},
        {value:2,label:'支持7天无理由(定制商品除外)'},
      ],
      PeriodTypeList:[
        {value:1,label:'每日'},
        {value:2,label:'每周'},
        {value:3,label:'每月'},
        {value:4,label:'每年'},
      ],
      two:/^\d+(\.\d{1,2})?$/,
      three:/^\d+(\.\d{1,3})?$/,
      batchSetShow:false,
      dialogImageUrl:'',
      dialogVisible:false,
      firstSearchCategory:true,
      firstSearchBrand:true,
      Categoryloading:false,
      Brandloading:false,
      FreightTemplateloading:false,
      ProductAttrloading:false,
    }
  },
  async mounted () {
    try{
      this.loading = true
      // await this.getCategory()
      // await this.getBrand()
      await this.getLogistics()
      if(this.$route.query.Id>0){
        this.getProductDetail(this.form.FreightTemplateId)
      }
    }finally{
      this.loading = false
    }
  },
  methods:{
    async refreshLogistics(){
      try{
        await this.getLogistics()
        this.getTpDetail(this.form.FreightTemplateId)
      }finally{
        //
      }
    },
    ShowSet(){
      this.batchSetShow = true
      this.$nextTick(()=>{
        this.$refs.setInput.focus()
      })
    },
    searchCategory(visible){
      if(visible&&this.firstSearchCategory){
        this.firstSearchCategory = false
        this.getCategory()
      }
    },
    searchBrand(visible){
      if(visible&&this.firstSearchBrand){
        this.firstSearchBrand = false
        this.getBrand()
      }
    },
    async getProductDetail(){
      try{
        this.loading = true
        let res = await channelShopchannelShopProductDetail({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          form.Limited = form.LimitedBuyNum>0
          if(!form.Limited){
            form.PeriodType = 1
            form.LimitedBuyNum = ''
          }
          form.Weight = form.Weight||''
          form.category = `${form.FirstCategoryId}>${form.SecondCategoryId}>${form.ThirdCategoryId}`
          // console.log(form.SpecList)
          form.SpecList = JSON.parse(JSON.stringify(form.SpecList.map(v=>{
            v.Price = v.Price||v.SpecPrice
            return v
          })))
          form.ProductAttr = form.ProductAttr.map(v=>{
            return {
              value:v.attr_value,
              name:v.attr_key
            }
          })
          form.tpDetail = null
          if(form.BrandId){
            this.brandList = [{
              Id:form.BrandId,
              label:form.BrandName
            }]
          }
          this.categoryOptions = [{
            Id:`${form.FirstCategoryId}>${form.SecondCategoryId}>${form.ThirdCategoryId}`,
            label:`${form.FirstCategoryName}>${form.SecondCategoryName}>${form.ThirdCategoryName}`
          }]
          this.form = JSON.parse(JSON.stringify(form))
          this.getTpDetail(form.FreightTemplateId)
          this.getCategoryDetail(form.category,true)
        }
      }finally{
        this.loading = false
      }
    },
    getBrandName(e){
      let brand = this.brandList.find(v=>v.Id==e)
      if(brand&&brand.Id){
        this.form.BrandName = brand.label
      }
    },
    async getCategoryDetail(e,first){
      this.ProductAttrloading = true
      let oldList = []
      if(first){
        oldList = JSON.parse(JSON.stringify(this.form.ProductAttr))
      }
      let list = e.split('>')
      this.form.FirstCategoryId = list[0]
      this.form.SecondCategoryId = list[1]
      this.form.ThirdCategoryId = list[2]
      let category = this.categoryOptions.find(v=>v.Id==e)
      if(category&&category.Id){
        let namelist = category.label.split('>')
        this.form.FirstCategoryName = namelist[0]
        this.form.SecondCategoryName = namelist[1]
        this.form.ThirdCategoryName = namelist[2]
      }
      try{
        let res = await channelShopchannelShopCategoryDetail({
          CategoryId:this.form.ThirdCategoryId,
          ChannelShopProductId:this.form.Id
        })
        if(res.IsSuccess){
          this.form.ProductAttr = [...res.Result.product_attr_list,...res.Result.sale_attr_list].map((v,i)=>{
            if(v.type=='select_one'){
              v.Options = v.value.split(';').map(x=>{
                return {
                  value:x,
                  label:x
                }
              })
              v.value = ''
            }
            //编辑进来的时候要展示下拉框又不影响原来的选项
            if(!first){
              v.value = ''
            }else{
              v.value = oldList[i].value
            }
            return v
          })
        }
      }finally{
        this.ProductAttrloading = false
      }
    },
    onSuccess(file){
      if(this.form.Qualifications&&this.form.Qualifications.length>=5){
        this.form.Qualifications = this.form.Qualifications.filter((v,i)=>{
          return i<5
        })
        this.$message.error('最多上传五图片')
        return
      }
      this.form.Qualifications.push(file[0])
    },
    handlePictureCardPreview(file){
      this.dialogImageUrl = this.imgUrl + file
      this.dialogVisible = true
    },
    handleRemove(i){
      this.form.Qualifications.splice(i,1)
    },
    beforeUpload(file,filelist){
      const size = file.size / 1024 / 1024 < 10;
      const testtype = ['image/bmp', 'image/png', 'image/jpeg', 'image/jpg']
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在10M以内');
        return false
      }
    },
    getTpDetail(e){
      let template = this.logisticsOptions.find(v=>v.Id==e)
      // console.log(template,e,this.logisticsOptions)
      if(template&&template.Id){
        this.form.FreightTemplateName = template.label
        this.form.tpDetail = {
          ...template,
          Defaule:template.freight_calc_method_list.find(v=>v.is_default)||{},
          NotDefaule:template.freight_calc_method_list.filter(v=>!v.is_default),

        }
        // console.log(this.form.tpDetail)
      }else{
        this.form.tpDetail = null
      }
    },
    confirmSet(){
      this.$refs.ruleForm.validateField('batchShopPrice',(error)=>{
        if(!error){
          this.form.SpecList = this.form.SpecList.map(v=>{
            v.Price = this.form.batchShopPrice
            return v
          })
          this.batchSetShow=false
          this.form.batchShopPrice=''
        }
      })
    },
    async getLogistics(){
      try{
        this.FreightTemplateloading = true
        let res = await channelShopchannelShopFreightTemplateList({
          ChannelShopProductId:this.form.Id
        })
        if(res.IsSuccess){
          this.logisticsOptions = res.Result.map(v=>{
            v.freight_calc_method_list = v.freight_calc_method_list.map(x=>{
              // console.log(x)
              x.first_price = x.first_price>0?x.first_price*100/10000:0
              x.second_price = x.second_price>0?x.second_price*100/10000:0
              return x
            })
            v.Id = v.template_id
            v.label = v.name
            return v
          })
        }
      }finally{
        this.FreightTemplateloading = false
      }
    },
    async getBrand(){
      this.Brandloading = true
      try{
        let res = await channelShopchannelShopBrandList({
          ChannelShopProductId:this.form.Id
        })
        if(res.IsSuccess){
          this.brandList = res.Result.map(v=>{
            return {
              Id:v.brand_id,
              label:v.ch_name
            }
          })
        }
      }finally{
        this.Brandloading = false
      }
    },
    async getCategory(){
      this.Categoryloading = true
      try{
        let res = await channelShopchannelShopCategoryList({
          ChannelShopProductId:this.form.Id
        })
        if(res.IsSuccess){
          this.categoryOptions = res.Result.map(v=>{
            return {
              Id:`${v.FirstCategoryId}>${v.SecondCategoryId}>${v.ThirdCategoryId}`,
              label:`${v.FirstCategoryName}>${v.SecondCategoryName}>${v.ThirdCategoryName}`
            }
          })
        }
      }finally{
        this.Categoryloading = false
      }
    },
    cutOut(e,key,length){
      this.form[key] = e.target.value.substring(0,length)
    },
    getSelectPros(e,isChecked){
      if(!isChecked){
        this.$message.error('至少选择一件商品')
        return 
      }
      this.form.ProductId = e.Id
      this.form.ProductName = e.Name
      this.form.SpecList = e.ProductSpecList
      let key = ['ProductType','ProductNo','ImgUrlComplete','SellPoint']
      key.forEach(v=>{
        this.form[v] = e[v]
      })
      this.form.SpecList = JSON.parse(JSON.stringify(this.form.SpecList.map(v=>{
        [v.SpecPrice='',v.Price=v.SpecPrice] = [v.Price,v.SpecPrice]
        v.ProductSpecId = v.Id
        return v
      })))
      // console.log(this.form.SpecList)
      this.form.Title = this.form.ProductName.substring(0,60)
      this.form.Description = this.form.SellPoint.substring(0,18)
      this.selectProShow = false
    },
    submit(formName){
      this.$refs[formName].validate((valid,obj) => {
        if (valid) {
          this.confirmSave()
        } else {
          this.toError('is-error')
          return false;
        }
      });
    },
    async confirmSave(){
      try{
        this.loading = true
        let form = JSON.parse(JSON.stringify(this.form))
        if(!form.Limited){
          form.LimitedBuyNum = ''
          form.PeriodType = 0
        }
        form.ProductAttr = form.ProductAttr.map(v=>{
          return {
            attr_key:v.name,
            attr_value:v.value
          }
        })
        form.tpDetail = undefined
        let res = await channelShopchannelShopProductSubmit(form)
        if(res.IsSuccess){
          this.$message.success('提交成功')
          this.$router.push({path:'/videoShop/videoGoodsList'})
        }
      }finally{
        this.loading = false
      }
    },
    toError(name){
      this.$nextTick(()=>{
        this.$message.error('请完善商品配置')
        let iserror = document.getElementsByClassName(name)
        iserror[0].scrollIntoView({
          block:'center',
          behavior:'smooth'
        })
      })
    },
  }
}
</script>

<style lang='less' scoped>
::v-deep .label-star{
  .el-form-item__label::before{
    
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
}
.imgbox{
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 6px;
  border: 1px dashed #c0ccda;
  overflow: hidden;
  &:hover{
    .maskbox{
      display: block;
    }
  }
  img{
    width: 100%;
    max-height: 100%;
  }
  .maskbox{
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    .mask{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: rgba(0, 0, 0, .3);
      color: white;
      font-size: 15px;
      i{
        cursor: pointer;
      }
    }
  }
}
.aptitude{
  ::v-deep .el-upload--picture-card{
    width: 80px;
    height: 80px;
    line-height: 90px;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width: 80px;
    height: 80px;
    img{
      width: 100%;
      height: 100%;
    }
    i{
      font-size: 15px;
    }
  }
}
.board{
  background: #F8F8F9;border-radius: 2px 2px 2px 2px;
  padding:15px 20px;
}
.bottomCard{
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.table-bottom{
  // border-bottom: 1px solid #EBEEF5;
  padding: 5px 16px;
}
.shopBox{
  border: 1px solid #EBEEF5;
  padding:10px 16px;
  width: 500px;
  img{
    border-radius: 2px 2px 2px 2px;
    width:60px;
    height:60px;
    margin-right:10px;
    flex-shrink: 0;
  }
}
.btn-choose{
  background:#F0F2F5FF;color: #606266;
  border: none;
}
</style>